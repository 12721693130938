import React, { Fragment, SyntheticEvent } from 'react'
import { Layout } from '@components/Layout'
import { graphql, navigate } from 'gatsby'
import { mapToSEO, SEO } from '@components/SEO'
import { mapToPlateDesignListMobile } from '@mappers/ProductModels/mapper'
import { MobileSelectStyle } from '@components/AddToCart'
import { PlateCategoryMetaInfo, ProductModelCategoryList, VehicleTypesSetting, TransactionTypesSetting, VehicleType, TransactionType, CurrentPlateDesign, Brand, PlateColor, PlateDesignModel, ProductSize } from '@models/ProductModels'
import { DefaultCombination } from '@utils/Constant'
import { BasketLineResponseModel } from '@models/Basket/BasketLineResponseModel'
import { getVehicleTypeById, getTransactionTypeById, isMobile, mapToPlateSizes } from '@utils/Helpers'
import { Responsive, Loader, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { currentBasketSelector } from '@redux/selectors/basketSelector'
import { Record } from 'immutable'
import theme from '@styles/theme'
import { calculateDefaultPrice } from '@components/PlateDesignsPreview/designPreviewHelper'
import Axios from 'axios';
import { IVehicleType } from '@models/ProductModels/VehicleTypes'
import { ITransactionType } from '@models/ProductModels/TransactionType'
import { NavHeader } from '@components/Mobile/NavHeader/NavHeader'
import { Header } from '@components/SelectStyle/SelectStyle.styles'
import { AvailableSizeList, VehicleTypeList } from '@components/VehicleInfoList'
import { KenticoChoice } from '@utils/KontentTypes'
import { GenericBannerContainer } from '@components/GenericBannerContainer'

const queryString = require('query-string');
interface Props {
  data: any
  location: any
  apiUrl: string
  basketLineItems: BasketLineResponseModel[]
}
interface States {
  loading: boolean
  brand?: Brand
  infoBannerVisible: boolean
  combination: string
  loadingPrice: boolean
  plateColor: PlateColor | undefined
  frontPlate: PlateDesignModel | undefined
  backPlate: PlateDesignModel | undefined
  categoryName: string | undefined
  price: number
  fontMap: {
      CharacterMapSmall: { [key: string]: number },
      CharacterMapLarge: { [key: string]: number }
  } | undefined
  isGift?: boolean
  vehicleType: IVehicleType
  transactionType: ITransactionType
  currentPlateDesign: CurrentPlateDesign | undefined
  selectedPlateDesign?: {
    metaInfo: PlateCategoryMetaInfo,
    products: ProductModelCategoryList
  },
  availablePlateSizes: ProductSize[],
  availableVehicleTypes: string[]
}

class AddToCartPageView extends React.Component<Props, States>{

  CancelToken = Axios.CancelToken;
  source = this.CancelToken.source();

  constructor(props: Props) {
    super(props);
    const derivedStates = this.readState(props.location);

    let availableVehicleTypes: string[] = [];
    derivedStates.plateDesign?.vehicleTpes.forEach(vt => availableVehicleTypes.push(vt.codename));    
    var availablePlateSizes = mapToPlateSizes(derivedStates.plateDesign?.plateSizes ? derivedStates.plateDesign?.plateSizes : []);

    if(derivedStates.combination == DefaultCombination.Car || derivedStates.combination == DefaultCombination.Motorbike){
      if(typeof window !== 'undefined'){
        window.location.href = '/create';
      }
    }

    this.state = {
      loading: true,
      infoBannerVisible: false,
      combination : derivedStates.combination,
      selectedPlateDesign: derivedStates.plateDesign,
      categoryName: derivedStates.plateDesign?.metaInfo.name,
      loadingPrice: false,
      plateColor: derivedStates.currentDesign?.plateColor,
      frontPlate: derivedStates.currentDesign?.frontPlate,
      backPlate: derivedStates.currentDesign?.backPlate,
      brand: derivedStates.currentDesign?.brand,
      fontMap: undefined,
      price: derivedStates.plateDesign ? calculateDefaultPrice(derivedStates.plateDesign?.metaInfo, derivedStates.tt) : 0,
      isGift: derivedStates.isGift,
      vehicleType: derivedStates.vt,
      transactionType: derivedStates.tt,
      currentPlateDesign: derivedStates.currentDesign,
      availablePlateSizes: availablePlateSizes,
      availableVehicleTypes : availableVehicleTypes
    }
  }

  readState = (location: any) => {
    var formattedCombination = this.loadQuerystring(location).combination;
    var designId = this.loadQuerystring(location).designId;
    var vt = this.loadQuerystring(location).vt;
    var tt = this.loadQuerystring(location).tt;

    if(formattedCombination != DefaultCombination.Car && formattedCombination != DefaultCombination.Motorbike && formattedCombination && designId && vt && tt){      
        return this.loadPlateDesignListFromDesignId(formattedCombination, designId, getVehicleTypeById(vt), getTransactionTypeById(tt));      
    }
    return this.loadPlateDesignList();
  }


  loadPlateDesignListFromDesignId = (combination: string, designId : number, vt: IVehicleType, tt: ITransactionType) => {

    const list = mapToPlateDesignListMobile(this.props.data.kontentItemSelectStylePageMobile.elements.styles.linked_items, combination, vt);
    let index = 0;
    for(var i = 0; i< list.length; i++){
      list[i].products.categories.forEach(category =>{
        category.products.forEach(product => {
          if(product.id == designId) 
            index = i;
        })
      })
    }   
    const plateCategory = list[index].products.getPlateCategoryByPlateDesignCode(designId, designId);
    let currentDesign = undefined
    if(plateCategory) {
      currentDesign = list[index].products.buildPlateDesignModel(plateCategory, plateCategory?.plateColor, plateCategory?.brand, undefined);
    }
    
    return {
      vt: vt,
      combination: combination,
      tt: tt,
      currentDesign: currentDesign,
      confirmStyle: false,
      isGift: false,
      plateDesign: list[index] 
    }
  }

  loadPlateDesignList = (formattedCombination?: string | undefined, selectedCategory?: string | undefined, vehicleType?: VehicleType | undefined, transactionType?: TransactionType | undefined, currentDesign?: CurrentPlateDesign | undefined, isGift?: boolean) => {
    const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
    const combination = formattedCombination === undefined ? DefaultCombination.Car : formattedCombination;
    const tt = transactionType === undefined ? TransactionTypesSetting.New : transactionType;

    const list = mapToPlateDesignListMobile(this.props.data.kontentItemSelectStylePageMobile.elements.styles.linked_items, combination, vt);
    let plateDesign = list[0];
    if (selectedCategory !== undefined && selectedCategory !== null) {
      const selectedPlateDesign = list.find(l => l.metaInfo.name === selectedCategory);
      if (selectedPlateDesign !== undefined) {
        plateDesign = selectedPlateDesign;
      }
    }
    let cd = currentDesign === undefined ? plateDesign.products.buildDefaultPlateDesignProps() : currentDesign;
    let confirmStyle = currentDesign === undefined ? false : true;
    let isGifting = isGift === undefined ? false : isGift;
    return {
      plateDesign: plateDesign,
      vt: vt,
      combination: combination,
      tt: tt,
      currentDesign: cd,
      confirmStyle: confirmStyle,
      isGift: isGifting
    }
  }

  loadQuerystring = (location: any): { combination: string, designId : number , vt: number, tt: number} => {
    const query = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
    return {
      combination: query.combination,
      designId: query.productid,
      vt: query.vt,
      tt: query.tt
    };
  }

  onSelectedPlateDesign = (data: string) => {
    if (this.props.location.state === null || this.props.location.state === undefined) {
      const designList = this.loadPlateDesignList(undefined, data, undefined, undefined);
      const currentDesign = designList.plateDesign.products.buildDefaultPlateDesignProps()
      this.setState({
        selectedPlateDesign: designList.plateDesign,
        vehicleType: designList.vt,
        transactionType: designList.tt,
        combination: designList.combination,
        currentPlateDesign: currentDesign
      });

    } else {
      if (this.props.location.state.basketLine) {
        const { basketLine } = this.props.location.state;
        const vt = getVehicleTypeById(basketLine.vehicleTypeId);
        const tt = getTransactionTypeById(basketLine.transactionTypeId);
        const combination = basketLine.combinationLayout;
        const designList = this.loadPlateDesignList(combination, data, vt, tt);
        const currentDesign = designList.plateDesign.products.buildDefaultPlateDesignProps();
        this.setState({
          selectedPlateDesign: designList.plateDesign,
          vehicleType: designList.vt,
          transactionType: designList.tt,
          combination: designList.combination,
          currentPlateDesign: currentDesign
        });
      } else {
        const { combination, vehicleType, transactionType } = this.props.location.state;
        const designList = this.loadPlateDesignList(combination, data, vehicleType, transactionType);
        const currentDesign = designList.plateDesign.products.buildDefaultPlateDesignProps()
        this.setState({
          selectedPlateDesign: designList.plateDesign,
          vehicleType: designList.vt,
          transactionType: designList.tt,
          combination: designList.combination,
          currentPlateDesign: currentDesign
        });
      }

    }
  }

  render() {
    if(isMobile() === undefined){
      return null;
    }

    const { location, data } = this.props;
    const { elements } = data.kontentItemSelectStylePageMobile;
    const seo = mapToSEO(elements);
    const apiUrl = data.site.siteMetadata.apiUrl;
    const title = this.props.data.kontentItemSelectStylePageMobile.elements.step_title.linked_items[2].elements.title.value;        

    return <Layout version="simple" currentStep={2} location={location}>
      <SEO {...seo} />
      {
        this.state.selectedPlateDesign === undefined ? <Loader active inline='centered' conetnt="Loading..." /> :
          <Fragment>
            <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
              <Header>
                <Grid.Row mobile={16} tablet={16} computer={5} >
                  {isMobile() ? <NavHeader infoTextColor='white' link={"/select-a-style/?combination=" + this.state.combination + "&productid=" + this.state.frontPlate?.plateDesignCode + "&vt=" + this.state.vehicleType.id + "&tt=" + this.state.transactionType.id} info="Step 3/3" />: <></>}
                </Grid.Row>
              </Header>
              <Grid.Row mobile={16} tablet={16} computer={5} style={{marginTop: "20px"}}>
              <MobileSelectStyle
                  apiUrl={apiUrl}
                  title={title}
                  formattedCombination={this.state.combination}
                  vehicleType={this.state.vehicleType}
                  transactionType={this.state.transactionType}
                  selectedPlateDesign={this.state.selectedPlateDesign}
                  currentPlateDesign={this.state.currentPlateDesign}
                  handleSelectCard={this.onSelectedPlateDesign}
                  isGift={this.state.isGift} 
                  darkTheme={true}/>
              <GenericBannerContainer
                padding={{
                  mobile: {
                    top: 20,
                    bottom: 20
                  },
                  desktop: {
                    top: 40,
                    bottom: 40
                  }
                }}
                backgroundColor="#EBEBEB">
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <VehicleTypeList
                    title="Vehicle Types"
                    availableTypes={this.state.availableVehicleTypes}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <AvailableSizeList
                    title="Size Available"
                    availableSizes={this.state.availablePlateSizes} />
                </Grid.Column>
              </GenericBannerContainer>
              
              </Grid.Row>

            </Responsive>
          </Fragment>
      }
    </Layout>
  }
}

export const query = graphql`{
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemSelectStylePageMobile {
    elements {
      seo__noindex {
        value {
          name
          codename
        }
      }
      seo__nofollow {
        value {
          name
          codename
        }
      }
      seo__page_title {
        value
      }
      seo__page_description {
        value
      }
      page_info___checkout__title {
        value
      }
      url {
        value
      }
      step_title {
        linked_items {
          ... on KontentItemSelectStyleStep {
            elements {
              title{value}
              description{value}
              subtitle{value}
            }
          }
        }
      }
      styles {
        linked_items {
          ... on KontentItemSelectStyleGroup{
            elements {
              group_name {
                linked_items{
                  ... on KontentItemProductCategory {
                    elements {
                      category_name {
                        value
                      }
                      lock_caption_colors {
                        value {
                          codename
                          name
                        }
                      }
                      category_description_rich {
                        value
                      }
                      vehicle_types {
                        value {
                          name
                          codename
                        }
                      }
                      plate_sizes {
                        value {
                          name
                          codename
                        }
                      }
                      new_design {
                        value {
                          codename
                          name
                        }
                      }
                      fixed_fomat_price {
                        value
                      }
                      flexi_format_price {
                        value
                      }
                      upgrade_starting_price {
                        value
                      }
                      redesign_starting_price {
                        value
                      }
                      premium_3_character_price {
                        value
                      }
                      premium_2_character_price {
                        value
                      }
                      product_designs {
                        linked_items {
                          ... on KontentItemPlateDesign {
                            elements {
                              name {
                                value
                              }
                              dealer_only_design {
                                value {
                                  name
                                  codename
                                }
                              }
                              disable_dual_sizing {
                                value {
                                  codename
                                  name
                                }
                              }
                              authorized_dealers {
                                linked_items {
                                  ... on KontentItemAuthorizedDealers {
                                    elements {
                                      title {
                                        value
                                      }
                                      link_message {
                                        value
                                      }
                                      description {
                                        value
                                      }
                                      dealer_contacts {
                                        linked_items {
                                          ... on KontentItemDealerContact {
                                            elements {
                                              dealer_name {
                                                value
                                              }
                                              contact_name {
                                                value
                                              }
                                              phone {
                                                value
                                              }
                                              formatted_phone_number {
                                                value
                                              }
                                              dealer_id {
                                                value
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              transaction_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              vehicle_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_design_code {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_foreground_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_size {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              base_price {
                                value
                              }
                              redesign_price {
                                value
                              }
                              govt_upgrade_price {
                                value
                              }
                              captions {
                                value {
                                  name
                                  codename
                                }
                              }
                              max_message_length__mm_ {
                                value
                              }
                              bottom_caption_max_length__mm_ {
                                value
                              }
                              message_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                              european_brand_name {
                                value {
                                  name
                                  codename
                                }
                              }
                              dual_size_plate {
                                linked_items {
                                  ... on KontentItemPlateDesign {
                                    elements {
                                      name {
                                        value
                                      }
                                      dealer_only_design {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      disable_dual_sizing {
                                        value {
                                          codename
                                          name
                                        }
                                      }
                                      authorized_dealers {
                                        linked_items {
                                          ... on KontentItemAuthorizedDealers {
                                            elements {
                                              title {
                                                value
                                              }
                                              link_message {
                                                value
                                              }
                                              description {
                                                value
                                              }
                                              dealer_contacts {
                                                linked_items {
                                                  ... on KontentItemDealerContact {
                                                    elements {
                                                      dealer_name {
                                                        value
                                                      }
                                                      contact_name {
                                                        value
                                                      }
                                                      phone {
                                                        value
                                                      }
                                                      formatted_phone_number {
                                                        value
                                                      }
                                                      dealer_id {
                                                        value
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                      transaction_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      vehicle_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_design_code {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_foreground_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_size {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      base_price {
                                        value
                                      }
                                      redesign_price {
                                        value
                                      }
                                      govt_upgrade_price {
                                        value
                                      }
                                      captions {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      max_message_length__mm_ {
                                        value
                                      }
                                      bottom_caption_max_length__mm_ {
                                        value
                                      }
                                      message_colors {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      european_brand_name {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

const mapStateToProps = (state: Record<any>) => {
  const basket = currentBasketSelector(state).toJS();
  return {
    basketLineItems: basket.basketLines,
  };
}

const AddToCartPage = connect(mapStateToProps)(AddToCartPageView)
export default AddToCartPage;